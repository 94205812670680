<template>
  <div class="main-container-LBG">
    <loginBG></loginBG>
    <el-main class="background-container">
      <div style="width: 1300px;position: absolute;top: 90px">
        <div class="forgotPwd-container">
          <div class="login-title-style">忘记密码</div>
          <div class="divider-5" style="width: 136px"></div>
          <el-form>
            <div style="margin: 40px 0 0 45px">
              <p>电话号码</p>
              <el-input class="input-R" style="width: 335px" placeholder="请输入您的电话号码" autocomplete="off"></el-input>
            </div>
            <button type="submit" class="reg-button-style" style="margin: 40px 0 0 45px">
              <span class="login-word-style" style="font-size: 15px; font-weight: 500">发送重置密码的链接至邮箱</span>
            </button>
          </el-form>
        </div>
      </div>
    </el-main>
  </div>
</template>
<script>
import loginBG from "@/components/loginBG";

export default {
  name: "ForgotPwd",
  components: {
    loginBG
  }
}
</script>

<style>

</style>
